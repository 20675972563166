
h1,
h2,
h3,
h4,
h5,
p,
a,
span,
strong {
    color: $gray-dark;
    font-family: $font-family-sans-serif;
}

a {
    font-weight: bold;
    
    &:hover {
        color: $brand-primary;
    }
}