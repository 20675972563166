/*============================================================================================
                                                                                          Blog
============================================================================================*/
$structure: '.post';

#{$structure} {
    // for all devices
    &-wrapper {
        padding: 1rem;
    }

    &-header {
        &Date {
            float: right;
        }

        &Tags {
            background-color: $gray-dark;
            border-radius: 0.2rem;
            padding: 0.3rem;

            & a {
                color: #fff;
                padding: 0.3rem;
            }
        }

        &Img {
            & img {
                max-width: 100%;
                padding: .3rem;
            }
        }
    }

    &-title {
        margin-top: 30px;
    }

    &-wrapperLeft {
        &--fixed {
            text-align: center;
        }
    }

    // for mobile devices
    @include media-breakpoint-up(xs) {
        &-wrapper {
            padding: 40px 0 0 0;
        }

        &-wrapperLeft {
            &--fixed {
                position: relative;
            }
        }

        &-leftCredentials {
            height: 100%;
            position: relative;
            top: 10%;
        }

        &-wrapperRight {
            &:before {
                content: none;
            }
        }
    }

    // for tablet devices
    @include media-breakpoint-up(md) {
        &-wrapperLeft {
            &--fixed {
                bottom: 0;
                height: 100vh;
                left: 0;
                position: fixed;
                right: 67%;
                top: 0;
            }
        }

        &-leftCredentials {
            height: 100vh;
        }

        &-wrapperRight {
            &:before {
                content: '';
                height: 100%;
                left: -10px;
                position: absolute;
                top: 0;
                width: 10px;
            }
        }
    }
}
