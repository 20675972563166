/*============================================================================================
                                                                                        Footer
============================================================================================*/
$structure: '.footer';

#{$structure} {
  // for all devices

  // for mobile devices
  @include media-breakpoint-up(xs) {
    &-blog {
      position: relative;
    }
  }

  // for tablet devices
  @include media-breakpoint-up(md) {
    &-blog {
      bottom: 10px;
      left: 0;
      position: fixed;
      right: 67%;
    }

    &-home {
      bottom: 10px;
      left: 0;
      position: fixed;
      right: 50%;
    }
  }
}