/*!
 * Bootstrap v4.0.0-alpha.2 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


// custom variables
@import "settings.variables";

// Core variables and mixins
@import "../bootstrap/scss/bootstrap/variables";
@import "../bootstrap/scss/bootstrap/mixins";

// Reset and dependencies
@import "../bootstrap/scss/bootstrap/normalize";
@import "../bootstrap/scss/bootstrap/print";

// Core CSS
@import "../bootstrap/scss/bootstrap/reboot";
@import "../bootstrap/scss/bootstrap/type";
@import "../bootstrap/scss/bootstrap/images";
@import "../bootstrap/scss/bootstrap/code";
@import "../bootstrap/scss/bootstrap/grid";
@import "../bootstrap/scss/bootstrap/tables";
@import "../bootstrap/scss/bootstrap/forms";
@import "../bootstrap/scss/bootstrap/buttons";

// Components
@import "../bootstrap/scss/bootstrap/animation";
@import "../bootstrap/scss/bootstrap/dropdown";
@import "../bootstrap/scss/bootstrap/button-group";
@import "../bootstrap/scss/bootstrap/input-group";
@import "../bootstrap/scss/bootstrap/custom-forms";
@import "../bootstrap/scss/bootstrap/nav";
@import "../bootstrap/scss/bootstrap/navbar";
@import "../bootstrap/scss/bootstrap/card";
@import "../bootstrap/scss/bootstrap/breadcrumb";
@import "../bootstrap/scss/bootstrap/pagination";
@import "../bootstrap/scss/bootstrap/pager";
@import "../bootstrap/scss/bootstrap/labels";
@import "../bootstrap/scss/bootstrap/jumbotron";
@import "../bootstrap/scss/bootstrap/alert";
@import "../bootstrap/scss/bootstrap/progress";
@import "../bootstrap/scss/bootstrap/media";
@import "../bootstrap/scss/bootstrap/list-group";
@import "../bootstrap/scss/bootstrap/responsive-embed";
@import "../bootstrap/scss/bootstrap/close";

// Components w/ JavaScript
@import "../bootstrap/scss/bootstrap/modal";
@import "../bootstrap/scss/bootstrap/tooltip";
@import "../bootstrap/scss/bootstrap/popover";
@import "../bootstrap/scss/bootstrap/carousel";

// Utility classes
@import "../bootstrap/scss/bootstrap/utilities";
@import "../bootstrap/scss/bootstrap/utilities-background";
@import "../bootstrap/scss/bootstrap/utilities-spacing";
@import "../bootstrap/scss/bootstrap/utilities-responsive";


// own styles
@import "tools.typography";
@import "generic.selection";
@import "objects.header";
@import "objects.content";
@import "objects.footer";
@import "objects.home";
@import "objects.blog";
@import "objects.post";
@import "trumps.helpers";
