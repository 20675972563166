/*============================================================================================
                                                                                          Home
============================================================================================*/
$structure: '.home';

#{$structure} {
  // for all devices
  &-wrapperLeft {
    &--fixed {
      text-align: center;
    }
  }

  &-twitter,
  &-github {
    display: inline-block;
    font-size: 2rem;
    vertical-align: middle;
  }

  // for mobile devices
  @include media-breakpoint-up(xs) {
    &-wrapperLeft {
      &--fixed {
        position: relative;
      }
    }

    &-leftCredentials {
      height: 100%;
      position: relative;
      top: 10%;

      & img {
        height: 100px;
      }
    }

    &-wrapperRight {
      &:before {
        content: none;
      }
    }
  }

  // for tablet devices
  @include media-breakpoint-up(md) {
    &-wrapperLeft {
      &--fixed {
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 50%;
        top: 0;
      }
    }

    &-leftCredentials {
      height: 100vh;

      & img {
        height: inherit;
      }
    }

    &-wrapperRight {
      &:before {
        background-color: $gray-dark;
        content: '';
        height: 100%;
        left: -10px;
        position: absolute;
        top: 0;
        width: 10px;
      }
    }
  }
}
